@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Cafe24Ssurround';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2105_2@1.0/Cafe24Ssurround.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
}

@layer base {
  body {
    @apply bg-color-bg px-[7.5rem] pt-32 font-pretendard text-md xs:text-sm xl:text-md 2xl:text-base;
  }

  h1,
  h2,
  h3,
  p,
  input,
  button,
  fieldset,
  textarea,
  ol,
  ul,
  li,
  img,
  a,
  button {
    @apply m-0 p-0;
  }

  p {
    @apply whitespace-nowrap;
  }

  div {
    @apply cursor-default select-none;
  }

  ol,
  ul,
  li {
    @apply list-none;
  }

  img {
    @apply h-auto max-w-full align-top;
  }

  a {
    @apply text-inherit no-underline;
  }

  button {
    @apply cursor-pointer border-0 tracking-m1 text-inherit transition duration-300 ease-out;
  }
}

@layer components {
  .box {
    @apply flex flex-col rounded-lg border border-gray-200 bg-white p-10;
  }

  .notice {
    @apply rounded-lg px-6 py-3;
    background-color: rgba(255, 202, 65, 0.5);
  }

  .greenBox {
    @apply rounded-lg px-1 py-2;
    background-color: rgba(3, 187, 163, 0.6);
  }

  .redBox {
    @apply rounded-lg px-1 py-2;
    background-color: rgba(232, 64, 87, 0.6);
  }

  .grayBox {
    @apply rounded-lg bg-gray-100 px-1 py-2;
  }

  .containerTitle {
    @apply text-base font-bold text-gray-900;
  }

  .boxTitle {
    @apply font-cafe24 text-lg font-bold text-gray-900;
  }

  .cardGrid2 {
    @apply grid gap-y-6;
    grid-template-columns: repeat(1, 1fr);
  }

  @screen xl {
    .cardGrid2 {
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem;
    }
  }

  .cardGrid3 {
    @apply mb-6 grid gap-6;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  }

  @screen lg {
    .cardGrid3 {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @screen xl {
    .cardGrid3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .cardGrid4 {
    @apply mb-6 grid gap-6;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  }

  @screen md {
    .cardGrid4 {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @screen xl {
    .cardGrid4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .problemGrid3 {
    @apply grid auto-rows-fr grid-cols-3 gap-x-10 gap-y-1 overflow-x-auto whitespace-nowrap bg-gray-100;
  }

  .MainGrid1to3 {
    @apply grid gap-y-6;
    grid-template-columns: repeat(1, 1fr);
  }

  @screen md {
    .MainGrid1to3 {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @screen lg {
    .MainGrid1to3 {
      grid-template-columns: repeat(4, 1fr);
      gap: 1.5rem;
    }
  }

  .DashboardGrid {
    @apply grid gap-6;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }

  @media (min-width: 1000px) {
    .DashboardGrid {
      grid-template-columns: 2fr 5fr;
      grid-template-rows: auto;
      gap: 1.5rem;
    }
  }

  /* 기본 2열 구조 */
  .ResponsiveGrid {
    @apply grid gap-6;
    grid-template-columns: 2fr 1fr;
  }

  @media (max-width: 800px) {
    .ResponsiveGrid {
      grid-template-columns: 1fr;
    }
  }

  .icon {
    @apply items-center justify-center p-0 text-2xl;
  }

  .tag {
    @apply inline-flex items-center rounded-full border-gray-500 bg-white px-3 py-1 text-sm font-semibold text-gray-600;
  }

  .hidden-scrollbar::-webkit-scrollbar {
    display: none; /* 웹킷 브라우저 (크롬, 사파리 등)에서 스크롤바 숨기기 */
  }

  .hidden-scrollbar {
    -ms-overflow-style: none; /* 인터넷 익스플로러에서 스크롤바 숨기기 */
    scrollbar-width: none; /* 파이어폭스에서 스크롤바 숨기기 */
  }

  .longSentence {
    @apply select-text whitespace-pre-wrap leading-7 text-gray-900;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 20rem;
    background-color: #6b7280;
    color: #fff;
    font-size: small;
    font-weight: 400;
    text-align: center;
    border-radius: 8px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    top: -25%;
    left: 150%;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}
